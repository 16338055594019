<template>
  <Dialog
    :visible="props.visible"
    modal
    class="pay-subscription-modal"
    :closable="false"
  >
    <template #header>
      <IconButton
        :icon="CloseGreyIcon"
        class="close-button"
        alt="Close dialog"
        :onclick="
          () => {
            setVisible(false);
          }
        "
        xsmall
      />
      <h1 v-if="loading || !successfulSubscription">Payment details</h1>
      <div v-else>
        <div
          class="pay-subscription-modal-icon-warpper"
        >
          <img
            :src="CheckmarkCircleGreyIcon"
          />
        </div>
        <h2>Congratulations!</h2>
      </div>
    </template>
    <div class="loading-screen" v-if="loading">
      <ProgressSpinner class="spinner bottom" />
    </div>
    <form
      class="payment-details-body"
      @submit.prevent="submitOrder"
      v-else-if="!successfulSubscription"
    >
      <div
        class="payment-info"
        v-if="!currentUserSubscriptionDetails.isFreeTrialEligible"
      >
        <label for="stripeInput">Card information</label>
        <div
          class="stripe-input-wrapper payment-input"
          :class="cardElementErrorMessage ? 'payment-input-error' : ''"
          id="stripeInput"
        >
          <StripeElements
            v-if="stripe"
            v-slot="{ elements }"
            ref="elms"
            :stripe-key="stripeKey"
            :instance-options="instanceOptions"
            :elements-options="elementsOptions"
          >
            <StripeElement
              ref="card"
              :elements="elements"
              @change="cardElementOnChange"
            />
          </StripeElements>
        </div>
        <div
          class="payment-info-error"
        >
          {{ cardElementErrorMessage }}
        </div>
      </div>
      <SubscriptionOverviewCard
        :subscriptionModel="selectedSubscriptionType"
        :formattedSubscriptionPrice="formattedSelectedSubscriptionPrice"
        class="subscription-overview-card"
        :freeTrialEligible="currentUserSubscriptionDetails.isFreeTrialEligible"
      />
      <div class="order-submission-error-message error">
        {{ orderSubmissionErrorMessage }}
      </div>
      <RoundedButton
        :text="
          currentUserSubscriptionDetails.isFreeTrialEligible
            ? 'Start free trial'
            : 'Pay ' + formattedSelectedSubscriptionPrice
        "
        :loading="orderSubmissionLoading"
        fitWidth
        accent
        lessRound
        uppercase
        class="action-button payment-button"
        :image="LockGreyIcon"
        type="submit"
        rightIcon
      />
    </form>
    <div class="successful-subscription" v-else>
      <div v-if="currentUserSubscriptionDetails.isFreeTrialEligible">
        <p>
          {{
            "You have successfully subscribed to our Free Trial.\nYou will receive an email confirmation shortly."
          }}
        </p>
        <p>
          Enjoy one month of unlimited recordings stored securely on our cloud,
          AI transcripts, pay-per-order notes for audio recordings and uploads,
          and study assistant.
        </p>
        <p>Make the most of your trial period!</p>
      </div>
      <div v-else>
        <p>
          {{
            "You have successfully subscribed to our plan.\nYou will receive an email confirmation shortly."
          }}
        </p>
        <p>
          Thank you for choosing our service, and we hope you have a great
          experience!
        </p>
      </div>
      <RoundedButton
        text="OK"
        accent
        tall
        wide
        lessRound
        uppercase
        class="action-button"
        @click="props.setVisible(false)"
      />
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import IconButton from "../IconButton.vue";
import RoundedButton from "../RoundedButton.vue";
import { SUBSCRIPTION_MODELS } from "../../assets/constants";
import SubscriptionOverviewCard from "./SubscriptionOverviewCard.vue";
import { loadStripe, PaymentIntentResult } from "@stripe/stripe-js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { getCurrentUserSubscriptionDetails } from "../../assets/helpers_legacy";
const LockGreyIcon = require("../../assets/images/Lock-grey.svg") as string;
const CheckmarkCircleGreyIcon =
  require("../../assets/images/Checkmark-circle-grey.svg") as string;
const CloseGreyIcon = require("../../assets/images/Close-grey.svg") as string;

interface Props {
  visible: boolean;
  setVisible: Function;
  formattedSelectedSubscriptionPrice: string;
  selectedSubscriptionType:
    | typeof SUBSCRIPTION_MODELS.MONTHLY.type
    | typeof SUBSCRIPTION_MODELS.YEARLY.type;
}
const props = defineProps<Props>();

const loading = ref(true);
const successfulSubscription = ref(false);
const orderSubmitted = ref(false);
const orderSubmissionLoading = ref(false);
const orderSubmissionErrorMessage = ref("");
const currentUserSubscriptionDetails = ref();
const stripe = ref();
const card = ref();
const elms = ref();
const instanceOptions = ref({
  // https://stripe.com/docs/js/initializing#init_stripe_js-options
});
const elementsOptions = ref({
  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
});

interface CardElementStatus {
  brand: string;
  complete: boolean;
  elementType: string;
  empty: boolean;
  error: {
    code: string;
    type: string;
    message: string;
  } | undefined;
  value: {
    postalCode: string;
  };
}

const cardElementStatus = ref<CardElementStatus|undefined>(undefined);
const cardElementErrorMessage = ref("");


const emit = defineEmits(["subscriptionSuccess"]);

const store = useStore();

const stripeKey = computed<string>(() => {
  switch (store.state.currentUser.location) {
    case "CA":
      return process.env.VUE_APP_STRIPE_PK_CA || "";
    case "GB":
      return process.env.VUE_APP_STRIPE_PK_GB || "";
    case "US":
      return process.env.VUE_APP_STRIPE_PK_US || "";
    default:
      return process.env.VUE_APP_STRIPE_PK_US || "";
  }
});

onBeforeMount(async () => {
  stripe.value = await loadStripe(stripeKey.value);

  currentUserSubscriptionDetails.value =
    await getCurrentUserSubscriptionDetails();
  loading.value = false;
});

function cardElementOnChange(event: CardElementStatus) {
  cardElementStatus.value = event;
  cardElementErrorMessage.value = "";
}



function submitOrder() {
  if (
    store.state.currentUser.role !== "Org User" &&
    cardElementStatus.value === undefined ||
    cardElementStatus.value && (
      cardElementStatus.value.error || 
      cardElementStatus.value.empty || 
      !cardElementStatus.value.complete
    )
  ) {
    console.log("Stripe card validator failed", cardElementStatus.value?.error)
    if (cardElementStatus.value === undefined) {
      cardElementErrorMessage.value = 'The card info is empty - please fill out the form';
    } else {
      cardElementErrorMessage.value = cardElementStatus.value.error &&
        cardElementStatus.value.error.message ||
        'There was an error with the card info - please check again';
    }

    return
  }

  orderSubmitted.value = true;
  orderSubmissionLoading.value = true;
  orderSubmissionErrorMessage.value = "";

  const data = {
    interval: props.selectedSubscriptionType,
  };

  axios
    .post(process.env.VUE_APP_MP_SERVER_API_URL + "self/subscription", data, {
      withCredentials: false,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("tokenMP") || "").accessToken,
      },
    })
    .then((response) => {
      if (response.data.status === "error") {
        if (response.data.message) {
          orderSubmissionErrorMessage.value = response.data.message;
        } else {
          orderSubmissionErrorMessage.value =
            "There was an error processing your subscription";
        }

        console.log("Error: ", response);
      } else if (response.data.status === "success") {
        if (response.data.data.subscription.status === "trialing") {
          // Auto-enrolled for free month trial
          onSuccessfulSubscription();
          return;
        } else {
          // Not eligible for free month trial, pay now
          elms.value.instance
            .confirmCardPayment(
              response.data.data.paymentIntent.client_secret,
              {
                payment_method: {
                  card: card.value.stripeElement,
                },
              }
            )
            .then((result: PaymentIntentResult) => {
              if (result.error) {
                if (result.error.message) {
                  orderSubmissionErrorMessage.value = result.error.message;
                } else {
                  orderSubmissionErrorMessage.value = "";
                }
                return;
              } else {
                // Successful subscription
                onSuccessfulSubscription();
              }
            });
        }
      }
      orderSubmissionLoading.value = false;
    })
    .catch((e) => {
      console.error("error while creating subscription:", e);
      orderSubmissionErrorMessage.value =
        "There was an error processing your subscription";
      orderSubmissionLoading.value = false;
    });
}

function onSuccessfulSubscription() {
  successfulSubscription.value = true;

  setTimeout(() => {
    emit("subscriptionSuccess");
  }, 3000);
}
</script>

<style lang="scss">
$side-padding: 2.4rem;

.pay-subscription-modal {
  background-color: map-get($colours, accent-purple-2);
  border-radius: 1.2rem;
  overflow: hidden;
  overflow-y: auto;
  width: 45.2rem;

  .p-dialog-header {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    color: map-get($colours, white);
    padding: 4rem $side-padding 0;

    .close-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    h1 {
      font-size: 2.4rem;
      margin: 0;
    }

    .pay-subscription-modal-icon-warpper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .p-dialog-content {
    background-color: inherit;
    padding: 0 $side-padding $side-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: map-get($colours, white);
    font-size: 1.4rem;
    font-family: $font-secondary;

    p {
      text-align: center;
      line-height: 1.6rem;
      letter-spacing: 0.1rem;
      margin: 1.6rem;
    }

    .payment-details-body {
      width: 100%;

      .payment-info {
        margin-top: 3.2rem;

        label {
          font-family: $font-secondary;
          font-weight: 600;
          font-size: 1.4rem;
          color: map-get($colours, grey);
        }

        .location-fields {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          .location-field {
            display: flex;
            flex-direction: column;
            margin-top: 1.6rem;
            width: 50%;
          }
        }
      }

      .order-submission-error-message {
        font-size: 1.4rem;
      }

      .subscription-overview-card {
        margin-bottom: 1.6rem;
      }

      .payment-button {
        margin-top: 2.4rem;
      }

      .payment-input {
        border: 0.2rem solid map-get($colours, accent-purple);
        padding: 1rem;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        background: transparent;
        color: map-get($colours, white);
        margin-top: 0.8rem;

        &:hover {
          border: 0.2rem solid map-get($colours, accent-purple-1);
        }

        &:focus {
          border: 0.2rem solid map-get($colours, accent-purple);
        }

        &::placeholder,
        .p-dropdown-label.p-placeholder,
        .p-dropdown-trigger {
          color: map-get($colours, grey-3);
        }

        .p-inputtext {
          color: map-get($colours, white);
          font-size: 1.4rem;
        }

        .p-dropdown-label {
          padding: 0;
        }
      }

      .payment-input-error {
        border: 0.2rem solid #b00020;
        background: #b000204c;
      }
    }

    .successful-subscription {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 1.6rem;

      header {
        h2 {
          margin: 2.4rem 0;
        }
      }

      p {
        font-size: 1.6rem;
        margin: 1.6rem 0;
        white-space: pre-line;
      }

      .action-button {
        margin-top: 5.6rem;
      }
    }

    .payment-info-error {
      color: #ffa6b6;
      height: 1.6rem;
      font-size: 1.2rem;
      font-family: $font-secondary;
      margin-top: 0.2rem;
    }

  }
}
</style>
